import styled from 'styled-components';

export const StyledArrowSlideProducts=styled.div`

position: relative;
.slide-img{
width:40px;
height:40px;    
}
.prev-slide-img{
position: absolute;
left:3%;
top:50%;
z-index: 30;
}
.next-slide-img{
position: absolute;
right:3%;
top:50%;
z-index: 30;
}


`