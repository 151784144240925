import styled from "styled-components";

export const StyledShopMain=styled.div`
/* .shop-nav{
margin-top:-30px ;
} */
.first-section{
padding-top: 100px;
.title{
font-family:'EnnVisionsBold' !important;
font-weight:500;
color: #363636;
@media screen and (max-width:575px){
flex-wrap: wrap;
}
}
.mt-40{
margin-top: 40px;
}
}

.footer-shop{
 margin-top:150px ;
}
`